exports.components = {
  "component---src-pages-404-de-jsx": () => import("./../../../src/pages/404.de.jsx" /* webpackChunkName: "component---src-pages-404-de-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-de-jsx": () => import("./../../../src/pages/about.de.jsx" /* webpackChunkName: "component---src-pages-about-de-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-de-jsx": () => import("./../../../src/pages/index.de.jsx" /* webpackChunkName: "component---src-pages-index-de-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industry-insights-energy-de-jsx": () => import("./../../../src/pages/industry-insights/energy.de.jsx" /* webpackChunkName: "component---src-pages-industry-insights-energy-de-jsx" */),
  "component---src-pages-industry-insights-energy-jsx": () => import("./../../../src/pages/industry-insights/energy.jsx" /* webpackChunkName: "component---src-pages-industry-insights-energy-jsx" */),
  "component---src-pages-industry-insights-maritime-de-jsx": () => import("./../../../src/pages/industry-insights/maritime.de.jsx" /* webpackChunkName: "component---src-pages-industry-insights-maritime-de-jsx" */),
  "component---src-pages-industry-insights-maritime-jsx": () => import("./../../../src/pages/industry-insights/maritime.jsx" /* webpackChunkName: "component---src-pages-industry-insights-maritime-jsx" */),
  "component---src-pages-industry-insights-utilities-de-jsx": () => import("./../../../src/pages/industry-insights/utilities.de.jsx" /* webpackChunkName: "component---src-pages-industry-insights-utilities-de-jsx" */),
  "component---src-pages-industry-insights-utilities-jsx": () => import("./../../../src/pages/industry-insights/utilities.jsx" /* webpackChunkName: "component---src-pages-industry-insights-utilities-jsx" */),
  "component---src-pages-legal-de-jsx": () => import("./../../../src/pages/legal.de.jsx" /* webpackChunkName: "component---src-pages-legal-de-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-plant-de-jsx": () => import("./../../../src/pages/plant.de.jsx" /* webpackChunkName: "component---src-pages-plant-de-jsx" */),
  "component---src-pages-plant-jsx": () => import("./../../../src/pages/plant.jsx" /* webpackChunkName: "component---src-pages-plant-jsx" */),
  "component---src-pages-privacy-de-jsx": () => import("./../../../src/pages/privacy.de.jsx" /* webpackChunkName: "component---src-pages-privacy-de-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

